import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createSubCategory = createAsyncThunk(
  "admin/create-subCategory",
  async ({ payload,categoryId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subCategoryPostURL.replace("{category_id}",categoryId),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createSubCategorySlice = createSlice({
  name: "create-subCategory",
  initialState: {
    createSubCategoryFetching: false,
    createSubCategorySuccess: false,
    createSubCategoryError: false,
    createSubCategoryErrorMessage: "",
  },
  reducers: {
    clearCreateSubCategoryState: (state) => {
      state.createSubCategoryError = false;
      state.createSubCategorySuccess = false;
      state.createSubCategoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createSubCategory.fulfilled]: (state, { payload }) => {
      state.createSubCategoryFetching = false;
      state.createSubCategorySuccess = true;
      return state;
    },
    [createSubCategory.rejected]: (state, action) => {
      state.createSubCategoryFetching = false;
      state.createSubCategoryError = true;
      state.createSubCategoryErrorMessage = action?.payload;
    },
    [createSubCategory.pending]: (state) => {
      state.createSubCategoryFetching = true;
    },
  },
});

export const { clearCreateSubCategoryState } = createSubCategorySlice.actions;

export default createSubCategorySlice.reducer;
