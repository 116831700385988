import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchProductList = createAsyncThunk(
  "admin/product-list",
  async ({ limit, offset, search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productListURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{search}", search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const productListSlice = createSlice({
  name: "product-list",
  initialState: {
    productList: [],
    productListFetching: false,
    productListSuccess: false,
    productListError: false,
    productListErrorMessage: "",
    productsCount: null,
  },
  reducers: {
    productListClearState: (state) => {
      state.productListError = false;
      state.productListSuccess = false;
      state.productListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchProductList.fulfilled]: (state, { payload }) => {
      state.productList = [];
      payload.data.results.forEach((item) => state.productList.push(item));
      state.productsCount = payload.data.count;
      state.productListFetching = false;
      state.productListSuccess = true;
      return state;
    },
    [fetchProductList.rejected]: (state, action) => {
      state.productListFetching = false;
      state.productListError = true;
      state.productListErrorMessage = action?.payload;
    },
    [fetchProductList.pending]: (state) => {
      state.productListFetching = true;
    },
  },
});

export const { productListClearState } = productListSlice.actions;

export default productListSlice.reducer;
