import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchInfoButtonStatusList = createAsyncThunk(
  "admin/InfoButtonStatus-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.InfoButtonStatusURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const InfoButtonStatusListSlice = createSlice({
  name: "InfoButtonStatus-list",
  initialState: {
    InfoButtonStatusList: {},
    InfoButtonStatusListFetching: false,
    InfoButtonStatusListSuccess: false,
    InfoButtonStatusListError: false,
    InfoButtonStatusListErrorMessage: "",
    InfoButtonStatusCount: null,
  },
  reducers: {
    InfoButtonStatusListClearState: (state) => {
      state.InfoButtonStatusListError = false;
      state.InfoButtonStatusListSuccess = false;
      state.InfoButtonStatusListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchInfoButtonStatusList.fulfilled]: (state, { payload }) => {
      state.InfoButtonStatusList = {};
      state.InfoButtonStatusList = payload.data;
      state.InfoButtonStatusCount = payload.data.count;
      state.InfoButtonStatusListFetching = false;
      state.InfoButtonStatusListSuccess = true;
      return state;
    },
    [fetchInfoButtonStatusList.rejected]: (state, action) => {
      state.InfoButtonStatusListFetching = false;
      state.InfoButtonStatusListError = true;
      state.InfoButtonStatusListErrorMessage = action?.payload;
    },
    [fetchInfoButtonStatusList.pending]: (state) => {
      state.InfoButtonStatusListFetching = true;
    },
  },
});

export const { InfoButtonStatusListClearState } =
  InfoButtonStatusListSlice.actions;

export default InfoButtonStatusListSlice.reducer;
