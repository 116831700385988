import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
//router
import { BrowserRouter } from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//store
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading1 from "./components/custom/loading1";

//reducer
import { store } from "./store";

import "./i18n";

ReactDOM.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <Suspense fallback={<Loading1 />}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert("New version available!  Ready to update?");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});
