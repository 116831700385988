import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const updateMenuButton = createAsyncThunk(
  "admin/update-menu-button",
  async ({ payload, buttonID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.menuButtonDetailsURL.replace("{id}", buttonID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const updateMenuButtonSlice = createSlice({
  name: "update-menu-button",
  initialState: {
    updateMenuButtonFetching: false,
    updateMenuButtonSuccess: false,
    updateMenuButtonError: false,
    updateMenuButtonErrorMessage: "",
  },
  reducers: {
    clearUpdateMenuButtonState: (state) => {
      state.updateMenuButtonError = false;
      state.updateMenuButtonSuccess = false;
      state.updateMenuButtonFetching = false;

      return state;
    },
  },
  extraReducers: {
    [updateMenuButton.fulfilled]: (state, { payload }) => {
      state.updateMenuButtonFetching = false;
      state.updateMenuButtonSuccess = true;
      return state;
    },
    [updateMenuButton.rejected]: (state, action) => {
      state.updateMenuButtonFetching = false;
      state.updateMenuButtonError = true;
      state.updateMenuButtonErrorMessage = action?.payload;
    },
    [updateMenuButton.pending]: (state) => {
      state.updateMenuButtonFetching = true;
    },
  },
});

export const { clearUpdateMenuButtonState } = updateMenuButtonSlice.actions;

export default updateMenuButtonSlice.reducer;
