import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteProduct = createAsyncThunk(
  "admin/delete-product",
  async ({ productID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productDetailsURL.replace("{id}", productID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteProductSlice = createSlice({
  name: "delete-product",
  initialState: {
    deleteProductFetching: false,
    deleteProductSuccess: false,
    deleteProductError: false,
    deleteProductErrorMessage: "",
  },
  reducers: {
    deleteProductClearState: (state) => {
      state.deleteProductError = false;
      state.deleteProductSuccess = false;
      state.deleteProductFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.deleteProductFetching = false;
      state.deleteProductSuccess = true;
      return state;
    },
    [deleteProduct.rejected]: (state, action) => {
      state.deleteProductFetching = false;
      state.deleteProductError = true;
      state.deleteProductErrorMessage = action?.payload;
    },
    [deleteProduct.pending]: (state) => {
      state.deleteProductFetching = true;
    },
  },
});

export const { deleteProductClearState } = deleteProductSlice.actions;

export default deleteProductSlice.reducer;
