import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createProductImage = createAsyncThunk(
  "admin/create-productImage",
  async ({ payload, productID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productImagePostURL.replace("{product_id}", productID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createProductImageSlice = createSlice({
  name: "create-productImage",
  initialState: {
    createProductImageFetching: false,
    createProductImageSuccess: false,
    createProductImageError: false,
    createProductImageErrorMessage: "",
  },
  reducers: {
    clearCreateProductImageState: (state) => {
      state.createProductImageError = false;
      state.createProductImageSuccess = false;
      state.createProductImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createProductImage.fulfilled]: (state, { payload }) => {
      state.createProductImageFetching = false;
      state.createProductImageSuccess = true;
      return state;
    },
    [createProductImage.rejected]: (state, action) => {
      state.createProductImageFetching = false;
      state.createProductImageError = true;
      state.createProductImageErrorMessage = action?.payload;
    },
    [createProductImage.pending]: (state) => {
      state.createProductImageFetching = true;
    },
  },
});

export const { clearCreateProductImageState } = createProductImageSlice.actions;

export default createProductImageSlice.reducer;
