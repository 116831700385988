import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSubCategoryList = createAsyncThunk(
  "admin/subCategory-list",
  async ({ limit, offset ,categoryId  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subCategoryListURL.replace("{category_id}", categoryId).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subCategoryListSlice = createSlice({
  name: "subCategory-list",
  initialState: {
    subCategoryList: [],
    subCategoryListFetching: false,
    subCategoryListSuccess: false,
    subCategoryListError: false,
    subCategoryListErrorMessage: "",
    subCategoryCount: null,
  },
  reducers: {
    subCategoryListClearState: (state) => {
      state.subCategoryListError = false;
      state.subCategoryListSuccess = false;
      state.subCategoryListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubCategoryList.fulfilled]: (state, { payload }) => {
      state.subCategoryList = [];
      payload.data.results.forEach((item) => state.subCategoryList.push(item));
      state.subCategoryCount = payload.data.count;
      state.subCategoryListFetching = false;
      state.subCategoryListSuccess = true;
      return state;
    },
    [fetchSubCategoryList.rejected]: (state, action) => {
      state.subCategoryListFetching = false;
      state.subCategoryListError = true;
      state.subCategoryListErrorMessage = action?.payload;
    },
    [fetchSubCategoryList.pending]: (state) => {
      state.subCategoryListFetching = true;
    },
  },
});

export const { subCategoryListClearState } = subCategoryListSlice.actions;

export default subCategoryListSlice.reducer;
