import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const updateAboutImage = createAsyncThunk(
  "admin/update-aboutImages",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.aboutImageURL,
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const updateAboutImageSlice = createSlice({
  name: "update-aboutImages",
  initialState: {
    updateAboutImageFetching: false,
    updateAboutImageSuccess: false,
    updateAboutImageError: false,
    updateAboutImageErrorMessage: "",
  },
  reducers: {
    clearUpdateAboutImageState: (state) => {
      state.updateAboutImageError = false;
      state.updateAboutImageSuccess = false;
      state.updateAboutImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [updateAboutImage.fulfilled]: (state, { payload }) => {
      state.updateAboutImageFetching = false;
      state.updateAboutImageSuccess = true;
      return state;
    },
    [updateAboutImage.rejected]: (state, action) => {
      state.updateAboutImageFetching = false;
      state.updateAboutImageError = true;
      state.updateAboutImageErrorMessage = action?.payload;
    },
    [updateAboutImage.pending]: (state) => {
      state.updateAboutImageFetching = true;
    },
  },
});

export const { clearUpdateAboutImageState } = updateAboutImageSlice.actions;

export default updateAboutImageSlice.reducer;
