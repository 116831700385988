import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const subCategoryEdit = createAsyncThunk(
  "admin/subCategory-edit",
  async ({payload, subCategoryID,categoryId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subCategoryDetailsURL.replace("{category_id}",categoryId).replace("{id}", subCategoryID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editSubCategorySlice = createSlice({
  name: "subCategory-edit",
  initialState: {
    editSubCategoryFetching: false,
    editSubCategorySuccess: false,
    editSubCategoryError: false,
    editSubCategoryErrorMessage: "",
  },
  reducers: {
    clearEditSubCategoryState: (state) => {
      state.editSubCategoryError = false;
      state.editSubCategorySuccess = false;
      state.editSubCategoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [subCategoryEdit.fulfilled]: (state, { payload }) => {
      state.editSubCategoryFetching = false;
      state.editSubCategorySuccess = true;
      return state;
    },
    [subCategoryEdit.rejected]: (state, action) => {
      state.editSubCategoryFetching = false;
      state.editSubCategoryError = true;
      state.editSubCategoryErrorMessage = action?.payload;
    },
    [subCategoryEdit.pending]: (state) => {
      state.editSubCategoryFetching = true;
    },
  },
});
export const { clearEditSubCategoryState } = editSubCategorySlice.actions;

export default editSubCategorySlice.reducer;
