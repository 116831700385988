import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createAboutImage = createAsyncThunk(
  "admin/create-aboutImages",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.aboutImageURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createAboutImageSlice = createSlice({
  name: "create-aboutImages",
  initialState: {
    createAboutImageFetching: false,
    createAboutImageSuccess: false,
    createAboutImageError: false,
    createAboutImageErrorMessage: "",
  },
  reducers: {
    clearCreateAboutImageState: (state) => {
      state.createAboutImageError = false;
      state.createAboutImageSuccess = false;
      state.createAboutImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createAboutImage.fulfilled]: (state, { payload }) => {
      state.createAboutImageFetching = false;
      state.createAboutImageSuccess = true;
      return state;
    },
    [createAboutImage.rejected]: (state, action) => {
      state.createAboutImageFetching = false;
      state.createAboutImageError = true;
      state.createAboutImageErrorMessage = action?.payload;
    },
    [createAboutImage.pending]: (state) => {
      state.createAboutImageFetching = true;
    },
  },
});

export const { clearCreateAboutImageState } = createAboutImageSlice.actions;

export default createAboutImageSlice.reducer;
