import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchMenuButton = createAsyncThunk(
  "admin/menu-button-details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.menuButtonURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const menuButtonDetailsSlice = createSlice({
  name: "menu-button-details",
  initialState: {
    menuButtonDetails: {},
    menuButtonDetailsFetching: false,
    menuButtonDetailsSuccess: false,
    menuButtonDetailsError: false,
    menuButtonDetailsErrorMessage: "",
  },
  reducers: {
    menuButtonDetailsClearState: (state) => {
      state.menuButtonDetailsError = false;
      state.menuButtonDetailsSuccess = false;
      state.menuButtonDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMenuButton.fulfilled]: (state, { payload }) => {
      state.menuButtonDetails = {};
      state.menuButtonDetails = payload.data;
      state.menuButtonDetailsFetching = false;
      state.menuButtonDetailsSuccess = true;
      return state;
    },
    [fetchMenuButton.rejected]: (state, action) => {
      state.menuButtonDetailsFetching = false;
      state.menuButtonDetailsError = true;
      state.menuButtonDetailsErrorMessage = action?.payload;
    },
    [fetchMenuButton.pending]: (state) => {
      state.menuButtonDetailsFetching = true;
    },
  },
});

export const { menuButtonDetailsClearState } = menuButtonDetailsSlice.actions;

export default menuButtonDetailsSlice.reducer;
