import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteSubCategory = createAsyncThunk(
  "admin/delete-subCategory",
  async ({ subCategoryID ,categoryId}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subCategoryDetailsURL.replace("{category_id}",categoryId).replace("{id}", subCategoryID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteSubCategorySlice = createSlice({
  name: "delete-subCategory",
  initialState: {
    deleteSubCategoryFetching: false,
    deleteSubCategorySuccess: false,
    deleteSubCategoryError: false,
    deleteSubCategoryErrorMessage: "",
  },
  reducers: {
    deleteSubCategoryClearState: (state) => {
      state.deleteSubCategoryError = false;
      state.deleteSubCategorySuccess = false;
      state.deleteSubCategoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteSubCategory.fulfilled]: (state, { payload }) => {
      state.deleteSubCategoryFetching = false;
      state.deleteSubCategorySuccess = true;
      return state;
    },
    [deleteSubCategory.rejected]: (state, action) => {
      state.deleteSubCategoryFetching = false;
      state.deleteSubCategoryError = true;
      state.deleteSubCategoryErrorMessage = action?.payload;
    },
    [deleteSubCategory.pending]: (state) => {
      state.deleteSubCategoryFetching = true;
    },
  },
});

export const { deleteSubCategoryClearState } = deleteSubCategorySlice.actions;

export default deleteSubCategorySlice.reducer;
