import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

//Dashboard
// const Index = lazy(() => import("../views/dashboard/index"));

//Menus
const Menus = lazy(() => import("../views/Menus/Menus"));
const MenuDashboard = lazy(() => import("../views/Menus/Submenu/MenuDashboard"));

//Categories
const Categories = lazy(() => import("../views/Categories/Categories"));

const SubCategories = lazy(() => import("../views/SubCategories/SubCategories"));


//Feedback
const Feedback = lazy(() => import("../views/Feedbacks/Feedbacks"));

//Settings
const Settings = lazy(() => import("../views/settings/index"));

//Profile
const UserProfile = lazy(() => import("../views/Profile/ProfileDashboard"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={200}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/menus" exact component={Menus} />
            <Route exact path="/menus/:menuID/:active_tab?" component={MenuDashboard} />

            <Route exact path="/categories" component={Categories} />

            <Route exact path="/categories/:categoryId/sub-categories" component={SubCategories} />
            
            <Route exact path="/feedbacks" component={Feedback} />

            <Route path="/settings/:active_tab?" component={Settings} />

            <Route path="/staff-profile" component={UserProfile} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
