import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const productEdit = createAsyncThunk(
  "admin/product-edit",
  async ({ payload, productID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productDetailsURL.replace("{id}", productID),
        method: "PATCH",
        data: payload,
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const editProductSlice = createSlice({
  name: "product-edit",
  initialState: {
    editProductFetching: false,
    editProductSuccess: false,
    editProductError: false,
    editProductErrorMessage: "",
  },
  reducers: {
    clearEditProductState: (state) => {
      state.editProductError = false;
      state.editProductSuccess = false;
      state.editProductFetching = false;

      return state;
    },
  },
  extraReducers: {
    [productEdit.fulfilled]: (state, { payload }) => {
      state.editProductFetching = false;
      state.editProductSuccess = true;
      return state;
    },
    [productEdit.rejected]: (state, action) => {
      state.editProductFetching = false;
      state.editProductError = true;
      state.editProductErrorMessage = action?.payload;
    },
    [productEdit.pending]: (state) => {
      state.editProductFetching = true;
    },
  },
});
export const { clearEditProductState } = editProductSlice.actions;

export default editProductSlice.reducer;
