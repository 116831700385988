import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteProductImage = createAsyncThunk(
  "admin/delete-productImage",
  async ({ productImageID, productID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productImageDetailsURL
          .replace("{id}", productImageID)
          .replace("{product_id}", productID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteProductImageSlice = createSlice({
  name: "delete-productImage",
  initialState: {
    deleteProductImageFetching: false,
    deleteProductImageSuccess: false,
    deleteProductImageError: false,
    deleteProductImageErrorMessage: "",
  },
  reducers: {
    deleteProductImageClearState: (state) => {
      state.deleteProductImageError = false;
      state.deleteProductImageSuccess = false;
      state.deleteProductImageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteProductImage.fulfilled]: (state, { payload }) => {
      state.deleteProductImageFetching = false;
      state.deleteProductImageSuccess = true;
      return state;
    },
    [deleteProductImage.rejected]: (state, action) => {
      state.deleteProductImageFetching = false;
      state.deleteProductImageError = true;
      state.deleteProductImageErrorMessage = action?.payload;
    },
    [deleteProductImage.pending]: (state) => {
      state.deleteProductImageFetching = true;
    },
  },
});

export const { deleteProductImageClearState } = deleteProductImageSlice.actions;

export default deleteProductImageSlice.reducer;
