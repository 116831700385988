import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchAboutImagesList = createAsyncThunk(
  "admin/aboutImages-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.aboutImageURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const aboutImagesListSlice = createSlice({
  name: "aboutImages-list",
  initialState: {
    aboutImagesList: {},
    aboutImagesListFetching: false,
    aboutImagesListSuccess: false,
    aboutImagesListError: false,
    aboutImagesListErrorMessage: "",
    aboutImagesCount: null,
  },
  reducers: {
    aboutImagesListClearState: (state) => {
      state.aboutImagesListError = false;
      state.aboutImagesListSuccess = false;
      state.aboutImagesListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAboutImagesList.fulfilled]: (state, { payload }) => {
      state.aboutImagesList = {};
      state.aboutImagesList = payload.data;
      state.aboutImagesCount = payload.data.count;
      state.aboutImagesListFetching = false;
      state.aboutImagesListSuccess = true;
      return state;
    },
    [fetchAboutImagesList.rejected]: (state, action) => {
      state.aboutImagesListFetching = false;
      state.aboutImagesListError = true;
      state.aboutImagesListErrorMessage = action?.payload;
    },
    [fetchAboutImagesList.pending]: (state) => {
      state.aboutImagesListFetching = true;
    },
  },
});

export const { aboutImagesListClearState } = aboutImagesListSlice.actions;

export default aboutImagesListSlice.reducer;
