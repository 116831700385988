import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchThemeSettings = createAsyncThunk(
  "admin/menu-button-details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.themeSettingsURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const themeSettingSlice = createSlice({
  name: "menu-button-details",
  initialState: {
    themeSettingDetails: {},
    themeSettingDetailsFetching: false,
    themeSettingDetailsSuccess: false,
    themeSettingDetailsError: false,
    themeSettingDetailsErrorMessage: "",
  },
  reducers: {
    themeSettingDetailsClearState: (state) => {
      state.themeSettingDetailsError = false;
      state.themeSettingDetailsSuccess = false;
      state.themeSettingDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchThemeSettings.fulfilled]: (state, { payload }) => {
      state.themeSettingDetails = {};
      state.themeSettingDetails = payload.data;
      state.themeSettingDetailsFetching = false;
      state.themeSettingDetailsSuccess = true;
      return state;
    },
    [fetchThemeSettings.rejected]: (state, action) => {
      state.themeSettingDetailsFetching = false;
      state.themeSettingDetailsError = true;
      state.themeSettingDetailsErrorMessage = action?.payload;
    },
    [fetchThemeSettings.pending]: (state) => {
      state.themeSettingDetailsFetching = true;
    },
  },
});

export const { themeSettingDetailsClearState } = themeSettingSlice.actions;

export default themeSettingSlice.reducer;
