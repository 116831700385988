import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createProduct = createAsyncThunk(
  "admin/create-product",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createProductSlice = createSlice({
  name: "create-product",
  initialState: {
    createProductFetching: false,
    createProductSuccess: false,
    createProductError: false,
    createProductErrorMessage: "",
  },
  reducers: {
    clearCreateProductState: (state) => {
      state.createProductError = false;
      state.createProductSuccess = false;
      state.createProductFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createProduct.fulfilled]: (state, { payload }) => {
      state.createProductFetching = false;
      state.createProductSuccess = true;
      return state;
    },
    [createProduct.rejected]: (state, action) => {
      state.createProductFetching = false;
      state.createProductError = true;
      state.createProductErrorMessage = action?.payload;
    },
    [createProduct.pending]: (state) => {
      state.createProductFetching = true;
    },
  },
});

export const { clearCreateProductState } = createProductSlice.actions;

export default createProductSlice.reducer;
