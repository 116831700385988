import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const updateInfoButtonStatus = createAsyncThunk(
  "admin/update-infoButtonStatuss",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.InfoButtonStatusURL,
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const updateInfoButtonStatusSlice = createSlice({
  name: "update-infoButtonStatuss",
  initialState: {
    updateInfoButtonStatusFetching: false,
    updateInfoButtonStatusSuccess: false,
    updateInfoButtonStatusError: false,
    updateInfoButtonStatusErrorMessage: "",
  },
  reducers: {
    clearUpdateInfoButtonStatusState: (state) => {
      state.updateInfoButtonStatusError = false;
      state.updateInfoButtonStatusSuccess = false;
      state.updateInfoButtonStatusFetching = false;

      return state;
    },
  },
  extraReducers: {
    [updateInfoButtonStatus.fulfilled]: (state, { payload }) => {
      state.updateInfoButtonStatusFetching = false;
      state.updateInfoButtonStatusSuccess = true;
      return state;
    },
    [updateInfoButtonStatus.rejected]: (state, action) => {
      state.updateInfoButtonStatusFetching = false;
      state.updateInfoButtonStatusError = true;
      state.updateInfoButtonStatusErrorMessage = action?.payload;
    },
    [updateInfoButtonStatus.pending]: (state) => {
      state.updateInfoButtonStatusFetching = true;
    },
  },
});

export const { clearUpdateInfoButtonStatusState } =
  updateInfoButtonStatusSlice.actions;

export default updateInfoButtonStatusSlice.reducer;
