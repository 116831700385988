import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchProductImageDetails = createAsyncThunk(
  "admin/productImage-details",
  async ({ productImageID, productID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productImageDetailsURL
          .replace("{id}", productImageID)
          .replace("{product_id}", productID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const productImageDetailsSlice = createSlice({
  name: "productImage-details",
  initialState: {
    productImageDetails: {},
    productImageDetailsFetching: false,
    productImageDetailsSuccess: false,
    productImageDetailsError: false,
    productImageDetailsErrorMessage: "",
  },
  reducers: {
    clearProductImageDetailsState: (state) => {
      state.productImageDetailsError = false;
      state.productImageDetailsSuccess = false;
      state.productImageDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchProductImageDetails.fulfilled]: (state, { payload }) => {
      state.productImageDetails = payload.data;

      state.productImageDetailsFetching = false;
      state.productImageDetailsSuccess = true;
      return state;
    },
    [fetchProductImageDetails.rejected]: (state, action) => {
      state.productImageDetailsFetching = false;
      state.productImageDetailsError = true;
      state.productImageDetailsErrorMessage = action?.payload;
    },
    [fetchProductImageDetails.pending]: (state) => {
      state.productImageDetailsFetching = true;
    },
  },
});
export const { clearProductImageDetailsState } =
  productImageDetailsSlice.actions;

export default productImageDetailsSlice.reducer;
