import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const updateThemeSetting = createAsyncThunk(
  "admin/update-theme",
  async ({ payload}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.themeSettingsURL,
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const updateThemeSettingSlice = createSlice({
  name: "update-theme",
  initialState: {
    updateThemeSettingFetching: false,
    updateThemeSettingSuccess: false,
    updateThemeSettingError: false,
    updateThemeSettingErrorMessage: "",
  },
  reducers: {
    clearUpdateThemeSettingState: (state) => {
      state.updateThemeSettingError = false;
      state.updateThemeSettingSuccess = false;
      state.updateThemeSettingFetching = false;

      return state;
    },
  },
  extraReducers: {
    [updateThemeSetting.fulfilled]: (state, { payload }) => {
      state.updateThemeSettingFetching = false;
      state.updateThemeSettingSuccess = true;
      return state;
    },
    [updateThemeSetting.rejected]: (state, action) => {
      state.updateThemeSettingFetching = false;
      state.updateThemeSettingError = true;
      state.updateThemeSettingErrorMessage = action?.payload;
    },
    [updateThemeSetting.pending]: (state) => {
      state.updateThemeSettingFetching = true;
    },
  },
});

export const { clearUpdateThemeSettingState } = updateThemeSettingSlice.actions;

export default updateThemeSettingSlice.reducer;
