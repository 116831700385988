import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

//Login
import userSlice from "./login/loginSlice";
import forgotPasswordSlice from "./login/forgotPasswordSlice";
import sendOtpSlice from "./login/sendOtpSlice";
import verifyOtpSlice from "./login/verifyOtpSlice";
// import userProfileSlice from "./profile/userProfileSlice";

//Menus
import menuListSlice from "./menus/menuListSlice";
import createMenuSlice from "./menus/createMenuSlice";
import menuDetailsSlice from "./menus/menuDetailsSlice";
import editMenuSlice from "./menus/editMenuSlice";
import deleteMenuSlice from "./menus/deleteMenuSlice";
import menuSettingDetailsSlice from "./menus/menuSettingDetailsSlice";
import editMenuSettingSettingSlice from "./menus/editMenuSettingSlice";
import ratingListSlice from "./menus/submenus/ratingListSlice";
import changeAvailabilitySlice from "./menus/changeAvailabilitySlice";

//SubMenus
import subMenuListSlice from "./menus/submenus/subMenuListSlice";
import subMenuDetailsSlice from "./menus/submenus/subMenuDetailsSlice";
import createSubMenuSlice from "./menus/submenus/createSubMenuSlice";
import editSubMenuSlice from "./menus/submenus/editSubMenuSlice";
import deleteSubMenuSlice from "./menus/submenus/deleteSubMenuSlice";

//Categories
import categoryListSlice from "./categories/categoryListSlice";
import createCategorySlice from "./categories/createCategorySlice";
import categoryDetailsSlice from "./categories/categoryDetailsSlice";
import editCategorySlice from "./categories/editCategorySlice";
import deleteCategorySlice from "./categories/deleteCategorySlice";

//Sub Categories
import subCategoryListSlice from "./subCategories/subCategoryListSlice";
import createSubCategorySlice from "./subCategories/createSubCategorySlice";
import subCategoryDetailsSlice from "./subCategories/subCategoryDetailsSlice";
import editSubCategorySlice from "./subCategories/editSubCategorySlice";
import deleteSubCategorySlice from "./subCategories/deleteSubCategorySlice";

//Feedbacks
import feedbackListSlice from "./Feedbacks/feedbackListSlice";

//Settings
import businessDetailsSlice from "./systemSettings/business/businessDetailsListSlice";
import businessEditSlice from "./systemSettings/business/businessDetailsEditSlice";
import workingDaysListSlice from "./systemSettings/workingDays/workingDaysListSlice";
import workingDayDetailsSlice from "./systemSettings/workingDays/workingDayDetailsSlice";
import workingDayEditSlice from "./systemSettings/workingDays/workingDayEditSlice";
import imagesListSlice from "./systemSettings/Images/imagesListSlice";
import createImageSlice from "./systemSettings/Images/createImageSlice";
import themeSettingSlice from "./systemSettings/themeSetting/themeSettingSlice";
import updateThemeSettingSlice from "./systemSettings/themeSetting/updateThemeSettingSlice";

//Banner Images
import bannerImagesListSlice from "./systemSettings/bannerImages/bannerImagesListSlice";
import createBannerImageSlice from "./systemSettings/bannerImages/createBannerImageSlice";
import editBannerImageSlice from "./systemSettings/bannerImages/editBannerImageSlice";
import deleteBannerImageSlice from "./systemSettings/bannerImages/deleteBannerImageSlice";

//Products
import productListSlice from "./products/productListSlice";
import createProductSlice from "./products/createProductSlice";
import productDetailsSlice from "./products/productDetailsSlice";
import editProductSlice from "./products/editProductSlice";
import deleteProductSlice from "./products/deleteProductSlice";

//Product Images
import productImageListSlice from "./products/productimages/productImageListSlice";
import createProductImageSlice from "./products/productimages/createProductImageSlice";
import editProductImageSlice from "./products/productimages/editProductImageSlice";
import deleteProductImageSlice from "./products/productimages/deleteProductImageSlice";
import productImageDetailsSlice from "./products/productimages/productImageDetailsSlice";

import aboutImagesListSlice from "./systemSettings/aboutus/imagesListSlice";
import createAboutImageSlice from "./systemSettings/aboutus/createImageSlice";
import updateAboutImageSlice from "./systemSettings/aboutus/updateImageSlice";

import InfoButtonStatusListSlice from "./systemSettings/infoButton/infoButtonStatusSlice";
import updateInfoButtonStatusSlice from "./systemSettings/infoButton/updateInfoButtonSlice";

import menuButtonDetailsSlice from "./systemSettings/menubutton/buttonDetailsSlice";
import createMenuButtonSlice from "./systemSettings/menubutton/createButtonSlice";
import updateMenuButtonSlice from "./systemSettings/menubutton/updateButtonSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    forgotPasswordSlice,
    sendOtpSlice,
    verifyOtpSlice,
    //Menus
    menuListSlice,
    createMenuSlice,
    menuDetailsSlice,
    editMenuSlice,
    deleteMenuSlice,
    menuSettingDetailsSlice,
    editMenuSettingSettingSlice,
    ratingListSlice,
    changeAvailabilitySlice,
    //SubMenus
    subMenuListSlice,
    subMenuDetailsSlice,
    createSubMenuSlice,
    editSubMenuSlice,
    deleteSubMenuSlice,
    //Categories
    categoryListSlice,
    createCategorySlice,
    categoryDetailsSlice,
    editCategorySlice,
    deleteCategorySlice,
    //Feedbacks
    feedbackListSlice,
    //Settings
    businessDetailsSlice,
    businessEditSlice,
    workingDaysListSlice,
    workingDayDetailsSlice,
    workingDayEditSlice,
    imagesListSlice,
    createImageSlice,
    bannerImagesListSlice,
    createBannerImageSlice,
    editBannerImageSlice,
    deleteBannerImageSlice,
    productListSlice,
    createProductSlice,
    productDetailsSlice,
    editProductSlice,
    deleteProductSlice,
    productImageListSlice,
    createProductImageSlice,
    editProductImageSlice,
    deleteProductImageSlice,
    productImageDetailsSlice,
    aboutImagesListSlice,
    createAboutImageSlice,
    InfoButtonStatusListSlice,
    updateInfoButtonStatusSlice,
    updateAboutImageSlice,
    menuButtonDetailsSlice,
    createMenuButtonSlice,
    updateMenuButtonSlice,
    themeSettingSlice,
    updateThemeSettingSlice,
    subCategoryListSlice,
    createSubCategorySlice,
    subCategoryDetailsSlice,
    editSubCategorySlice,
    deleteSubCategorySlice,
    
  },
});
